import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

function Graph(props) {
	const options = props.options || {};
	options.accessibility = {
		enabled: false
	};

	return (
		<ErrorBoundary>
			<figure className="Graph">
				<HighchartsReact
					containerProps={{ style: { height: "100%" } }}
					highcharts={ Highcharts }
					options={ options }
				/>
			</figure>
		</ErrorBoundary>
	);
}

export default Graph;