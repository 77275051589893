function Logo(props) {
	return (
		<div className="Logo-container" onClick={ props.onClick }>
			<div className="Logo-artContainer">
				<span className="Logo-c"></span>
				<span className="Logo-k"></span>
			</div>
			<span className="Logo-fullName">Kyle Crossman</span>
		</div>
	);
}

export default Logo;