import { useState, useEffect, useRef } from 'react';
import './CarouselMapView.css';
import Map from '../../Map/Map';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { useDimensions } from '../../../lib/useDimensions';

const coords = {
    lat: 43.074761,
    lng: -89.3837613
};

const countyDataColors = ["00B2CB", "2498A9", "487E87", "6C6465", "904A43", "B43021", "D61600"];

const demoMapStyle: google.maps.MapTypeStyle[] = [
	{
		stylers: [{ visibility: "off" }],
	},
	{
		featureType: "landscape",
		elementType: "geometry",
		stylers: [{ visibility: "on" }, { color: "#363636" }],
	},
	{
		featureType: "water",
		elementType: "geometry",
		stylers: [{ visibility: "on" }, { color: "#bfd4ff" }],
	},
];

const culversLocationsDaneCounty = [
	{
		content: "A Culver's",
		position: {
			lat: 43.0837331,
			lng: -89.3077947
		}
	},{
		content: "A Culver's restaurant",
		position: {
			lat: 43.1341299,
			lng: -89.3728847
		}
	},{
		content: "Culver's",
		position: {
			lat: 43.0360138,
			lng: -89.4150463
		}
	},{
		content: "Surprise! Culver's",
		position: {
			lat: 43.125192,
			lng: -89.309125
		}
	}, {
		content: "You guessed it. Culver's",
		position: {
			lat: 43.060726,
			lng: -89.507981
		}
	}, {
		content: "I'm delighted to inform you that this is a Culver's",
		position: {
			lat: 43.013408,
			lng: -89.302737
		}
	}, {
		content: "How'd you guess this was a Culver's?",
		position: {
			lat: 43.1061916,
			lng: -89.5112814
		}
	}, {
		content: "You've clicked on a Culver's",
		position: {
			lat: 43.1913431,
			lng: -89.4363233
		}
	}, {
		content: "A Culver's location",
		position: {
			lat: 43.1044307,
			lng: -89.1985393
		}
	}, {
		content: "A map marker depicting the location of a Culver's",
		position: {
			lat: 43.1782235,
			lng: -89.238725
		}
	}, {
		content: "A McDonald's. Just kidding. It's a Culver's",
		position: {
			lat: 42.991914,
			lng: -89.529588
		}
	}, {
		content: "This is a Culver's",
		position: {
			lat: 43.250333,
			lng: -89.374772
		}
	}, {
		content: "Yes, it's Culver's",
		position: {
			lat: 42.92653,
			lng:  -89.2497055
		}
	}, {
		content: "The best burger chain in the USA, Culver's",
		position: {
			lat: 43.1861695,
			lng: -89.2672529
		}
	}, {
		content: "Did someone say Culver's?",
		position: {
			lat: 43.0106795,
			lng: -89.7178223
		}
	}, {
		content: "Culver's, the home of the Butter Burger",
		position: {
			lat: 42.9165158,
			lng: -89.3692866
		}
	}, {
		content: "Somewhere to get delicious custard. Culver's",
		position: {
			lat: 43.1112993,
			lng: -89.6442073
		}
	}
];

function CarouselMapView(props) {
	const [isLoadingData, setIsLoadingData] = useState(false);
	const [countyData, setCountyData] = useState(null);
	const [min, setMin] = useState(null);
	const [max, setMax] = useState(null);
	const mapViewRef = useRef(null);
	const { width } = useDimensions(mapViewRef);

	/**
	 * Handle the "Load Data" button being clicked
	 */
	useEffect(() => {
		setIsLoadingData(true);
		const countyMap = {};
		fetch('./data/County_Boundaries_24K.geojson')
			.then(response => response.text()) 
			.then(json => {
				const countyShapes = JSON.parse(json)
				countyShapes.features.forEach(countyShape => {
					countyMap[countyShape.properties.COUNTY_NAME] = countyShape;
				});

				fetch('data/WisconsinCounties.csv')
					.then(response => response.text()) 
					.then(csv => {
						let min = null;
						let max = null;
						const rows = csv.split('\n');

						// Aggregate
						const rentMap = {};
						for (let i = 1; i < rows.length; i++) {
							const county = rows[i].split(',');
							if (county[0] !== "") {
								const countyName = county[0].replace(' County', '');
								const rent = Number(county[1].replace("\\r", ""));
								rentMap[countyName] = rent;

								if (!max || rent > max) {
									max = rent;
								}

								if (!min || rent < min) {
									min = rent;
								}
							}
						}

						const difference = max - min;
						const interval = difference / 7;

						countyShapes.features = Object.keys(countyMap).map(countyName => {
							countyMap[countyName].properties.color = countyDataColors[Math.floor((rentMap[countyName] - min) / interval)];
							return countyMap[countyName];
						});

						setCountyData(countyShapes);
						setMin(min);
						setMax(max);
						setIsLoadingData(false);
					});
			});
	}, []);

	let difference = null;
	let interval = null;
	if (min && max) {
		difference = max - min;
		interval = difference / 7;
	}

	/**
	 * Render the button states
	 *
	 * @return     JSX
	 */
	const renderButton = () => {
		let buttonContent = "Load Demographics Data";

		if (isLoadingData) {
			buttonContent = (<><LoadingSpinner /> Loading...</>);
		} else if (interval) {
			buttonContent = (
				<>
					Rent By County:
					<div className="CarouselMapView-legend">
						{ countyDataColors.map((color, i) => {
							return <figure title={ `${ Math.round(min + interval * i) } to ${ Math.round(min + interval * (i+1)) }`} style={{backgroundColor: `#${color}`}} key={ color } />	
						}) }
					</div>
				</>
			);	
		}

		return (
			<div 
				className="CarouselMapView-loadButton"
				onClick={ () => {
					if (!interval && !isLoadingData) {
						setIsLoadingData(true);
					}
				}}
			>{ buttonContent }</div>
		);
	};

	let daneCountyData;
	if (countyData) {
		daneCountyData = Object.assign({}, countyData);
		daneCountyData.features = [countyData.features.find(county => {
			return county.properties.COUNTY_NAME === 'Dane';
		})];
	}

	return (
		<ErrorBoundary>
			<div className={ `${ props.className } CarouselMapView` } ref={ mapViewRef }>
				<Map 
					id="Portfolio-Map-1"
					shapes={ daneCountyData }
					center={ coords }
					points={ culversLocationsDaneCounty }
					strokeWidth={ 2 }
					markerColor="#005599"
					markerGlyphColor="#fff"
					shapeColor="005599"
					mapId="61fd5f7f11653ae6"
					baseOpacity={ 0.5 }
					useShapeInteraction={ false }
				/>
				<Map 
					id="Portfolio-Map-2"
					center={{lat: 44.6927, lng: -89.6082}}
					zoom={ width < 901 ? 6 : 7 }
					shapes={ countyData }
					loadButton={ renderButton() }
					styles={demoMapStyle}
					useShapeInteraction
					getShapeLabel={ feature => {
						return feature.getProperty('COUNTY_NAME');
					}}
				/>
			</div>
		</ErrorBoundary>
	);
}

export default CarouselMapView;