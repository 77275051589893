import { useState } from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Logo from './Logo/Logo';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { Outlet, Link, useNavigate, useLocation } from 'react-router-dom';
import './App.css';

import Map from '../Map/Map';

const NAV_MENU_OPTIONS = ["About", "Work", "Photography", "Contact"];

const points = [
    {
        content: (
            <div className="Map-popup">
                <img 
                    className="Map-popupImage"
                    src="img/madison.jpg"
                    style={{
                        objectPosition: '0 12%'
                    }}
                    alt="The capital building in Madison, WI"
                    title="The capital building in Madison, WI"
                />
                <img className="Map-headshot" src="img/headshot.png" alt="Kyle Crossman" />
                <h1>Hi, I'm Kyle</h1>
                <h3>I deliver web solutions</h3>
            </div>
        ),
        position: {
            lat: 43.074761,
            lng: -89.3837613
        }
    }, {
        content: (
            <div className="Map-popup">
                <img 
                    className="Map-popupImage"
                    src="img/kauai.jpg"
                    style={{
                        objectPosition: '0 50%'
                    }}
                    alt="A view looking out to the ocean from atop the Na'Pali coastline of Kauai, HI"
                    title="A view looking out to the ocean from atop the Na'Pali coastline of Kauai, HI"
                />
                <h1>Kauai, HI</h1>
                <h3>My first taste of paradise</h3>
            </div>
        ),
        position: {
            lat: 22.0504666,
            lng: -159.5587678
        }
    }, {
        content: (
            <div className="Map-popup">
                <img 
                    className="Map-popupImage"
                    src="img/cascades.jpg"
                    style={{
                        objectPosition: '0 50%'
                    }}
                    alt="An alpine landscape in North Cascades National Park"
                    title="An alpine landscape in North Cascades National Park"
                />
                <h1>North Cascades National Park</h1>
                <h3>A hiker's paradise</h3>
            </div>
        ),
        position: {
            lat: 48.5925302,
            lng: -121.3748758
        }
    }, {
        content: (
            <div className="Map-popup">
                <img 
                    className="Map-popupImage"
                    src="img/banff.jpg"
                    style={{
                        objectPosition: '0 12%'
                    }}
                    alt="The iconic mountain range of Moraine Lake"
                    title="The iconic mountain range of Moraine Lake"
                />
                <h1>Banff</h1>
                <h3>The unmatched splendor of the Canadian Rockies</h3>
            </div>
        ),
        position: {
            lat: 51.1777781,
            lng: -115.5682504
        }
    }, {
        content: (
            <div className="Map-popup">
                <img 
                    className="Map-popupImage"
                    src="img/disney.jpg"
                    style={{
                        objectPosition: '0 40%'
                    }}
                    alt="Disney World Magical Kingdom"
                    title="Disney World Magical Kingdom"
                />
                <h1>Disney World</h1>
                <h3>A magical wonderland</h3>
            </div>
        ),
        position: {
            lat: 28.3824072,
            lng: -81.5617078
        }
    }, {
        content: (
            <div className="Map-popup">
                <img 
                    className="Map-popupImage"
                    src="img/yellowstone.jpg"
                    style={{
                        objectPosition: '0 50%'
                    }}
                    alt="A rainbow in Great Yellowstone Falls"
                    title="A rainbow in Great Yellowstone Falls"
                />
                <h1>Yellowstone National Park</h1>
                <h3>My first foray into the mountains</h3>
            </div>
        ),
        position: {
            lat: 44.6200885,
            lng: -110.5606893
        }
    }
];

function App() {
  const [isMapOpen, setIsMapOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const render = (status: Status): ReactElement => {
    if (status === Status.FAILURE) return "ERROR";
    return "";
  };

  let apiKey;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    apiKey = process.env.REACT_APP_MAPS_API_KEY_LOCAL;
  } else {
    // production code
    apiKey = process.env.REACT_APP_MAPS_API_KEY;
  }

  return (
    <ErrorBoundary>
        <div className="App">
          <div className="App-header">        
            <div className="App-menuLogoContainer">
              <Logo onClick={ () => {
                setIsMapOpen(true);
                navigate('about'); 
              }} />
            </div>
            <ul className="App-menu">
              { NAV_MENU_OPTIONS.map((option, i) => {
                return (                    
                    <Link key={ option } to={ option.toLowerCase() } onClick={ () => { 
                      if (option.toLowerCase() !== 'about') {
                        setIsMapOpen(false);
                      }
                    }}>
                      <li
                        key={ option }
                        className={ location.pathname === `/${ option.toLowerCase() }` ? 'is-selected' : '' }
                      >
                            { option }
                      </li>
                    </Link>
                );
              })}
            </ul>
            <div className="App-menuTray">
              <button
                className="App-menuToggle App-button"
                title={ isMapOpen ? "Hide the Map" : "Show the Map" }
                onClick={ () => setIsMapOpen(!isMapOpen)}
                disabled={ location.pathname === '/work' || location.pathname === '/contact' }
              >
                { isMapOpen ? (<span className="chevron top"></span>) : (<span className="chevron bottom"></span>) }
              </button>
            </div> 
          </div>
          <div key="map" className={ `App-splashContainer ${ isMapOpen ? '' : 'is-hidden' }` }>
            <Wrapper apiKey={ apiKey } render={render}>
              <Map
                id="map"
                points={ points }
                zoom={ 5 }
                openFirstPoint
              />
            </Wrapper>
          </div>
          <div className="App-content">
            <Outlet />
          </div>
        </div>
    </ErrorBoundary>
  );
}

export default App;
