import './CarouselDockerPage.css';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';

function CarouselDockerPage(props) {
	return (
		<ErrorBoundary>
			<div className={ `CarouselDockerPage ${ props.className }` }>
				<div className="CarouselDockerPage-before">
					<h2>Monolith Application</h2>
					<figure className="CarouselDockerPage-user">
						<span class="material-symbols-outlined">person</span> User
					</figure>
					<figure className="CarouselDockerPage-downArrow" />
					<figure className="CarouselDockerPage-frontEnd">
						<span class="material-symbols-outlined">computer</span> Front End
					</figure>
					<figure className="CarouselDockerPage-downArrow" />
					<div className="CarouselDockerPage-monolithApp">
						Rest API
						<figure className="CarouselDockerPage-service">
							<span class="material-symbols-outlined">grouped_bar_chart</span> Service A
						</figure>
						<figure className="CarouselDockerPage-service">
							<span class="material-symbols-outlined">map</span> Service B
						</figure>
						<figure className="CarouselDockerPage-service">
							<span class="material-symbols-outlined">fact_check</span> Service C
						</figure>
					</div>
				</div>
				<div className="CarouselDockerPage-after">
					<h2>Microservice Architecture</h2>
					<figure className="CarouselDockerPage-user">
						<span class="material-symbols-outlined">person</span> User
					</figure>
					<figure className="CarouselDockerPage-downArrow" />
					<figure className="CarouselDockerPage-frontEnd">
						<span class="material-symbols-outlined">computer</span> Front End
					</figure>
					<figure className="CarouselDockerPage-downArrow" />
					<div className="CarouselDockerPage-ecsApp">
						<div className="CarouselDockerPage-ecsService">
							Rest API
							<figure className="CarouselDockerPage-service">
								<span class="material-symbols-outlined">grouped_bar_chart</span> Service A
							</figure>
						</div>
						<div className="CarouselDockerPage-ecsService">
							Rest API
							<figure className="CarouselDockerPage-service">
								<span class="material-symbols-outlined">map</span> Service B
							</figure>
						</div>
						<div className="CarouselDockerPage-ecsService">
							Rest API
							<figure className="CarouselDockerPage-service">
								<span class="material-symbols-outlined">fact_check</span> Service C
							</figure>
						</div>
					</div>
				</div>
			</div>
		</ErrorBoundary>
	);
}

export default CarouselDockerPage;