import React, { useEffect, useState, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const MapControl = (props) => {
  const map = props.map;
  const ref = useRef();
  useEffect(() => {
    if (map && ref) {
      map.controls[window.google.maps.ControlPosition[props.position]].push(
        ref.current
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, ref]);
  return <div ref={ref}>{props.children}</div>;
};

const Map = React.memo(props => {
    const [map, setMap] = useState(null);

    const points = props.points || [];
    const shapes = props.shapes || {};
    const loadButton = props.loadButton || null;
    const position = props.center || points[0].position;
    const ref = useRef();
    const markerColor = props.markerColor || undefined;
    const markerGlyphColor = props.markerGlyphColor || undefined;
    const useShapeInteraction = props.useShapeInteraction;
    const baseOpacity = props.baseOpacity || 0.75;
    const strokeWidth = props.strokeWidth || 0.5;
    const shapeColor = props.shapeColor || undefined;

    useEffect(() => {
        const initializeMap = () => {
            if (window.google) {
                const map = new window.google.maps.Map(ref.current, {
                    center: position,
                    zoom: props.zoom,
                    styles: props.styles || undefined,
                    mapId: props.mapId || undefined
                });

                setMap(map);
            } else {
                setTimeout(initializeMap, 100);
            }
        }
       
        initializeMap();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function loadLibraries() {
        const { AdvancedMarkerElement, PinElement } = await window.google.maps.importLibrary("marker");
        return { AdvancedMarkerElement, PinElement };
    }

    const populateMap = (map, AdvancedMarkerElement = null, PinElement = null) => {
        if (map) {
            const info = new window.google.maps.InfoWindow({
                content: ""
            });

            const bounds = new window.google.maps.LatLngBounds();

            const useMarkerColors = (markerColor && markerGlyphColor) ||
                (points[0] && points[0].color);

            for (let i = 0; i < points.length; i++) {
                let marker;
                if (useMarkerColors &&
                    PinElement &&
                    AdvancedMarkerElement
                    ) {
                    const pinBackground = new PinElement({
                        background: points[i].color || markerColor,
                        borderColor: "#fff",
                        glyphColor: points[i].color || markerGlyphColor
                    });
                    marker = new AdvancedMarkerElement({
                        map,
                        position: points[i].position,
                        content: pinBackground.element
                    });
                } else {
                    marker = new window.google.maps.Marker({
                        map,
                        position: points[i].position,
                    });
                }

                if (points[i].content) {
                    marker.addListener("click", () => {
                        info.setContent(ReactDOMServer.renderToStaticMarkup(points[i].content));
                        info.open({
                            anchor: marker,
                            map
                        });
                    });

                    if (i === 0 && props.openFirstPoint) {
                        info.setContent(ReactDOMServer.renderToStaticMarkup(points[i].content));                
                        info.open({
                            anchor: marker,
                            map
                        });
                    }
                }

                bounds.extend(points[i].position);
            }

            if (Object.keys(shapes).length > 0) {
                map.data.unbindAll();
                window.google.maps.event.clearListeners(map.data, 'click');

                map.data.forEach(function(feature) {
                    map.data.remove(feature);
                });

                map.data.setStyle(feature => {
                    const color = shapeColor || feature.getProperty("color");
                    let strokeWeight = strokeWidth || 0.5;
                    let zIndex = 1;
                    let opacity = baseOpacity || 0.75;
                    if (feature.getProperty("state") === "hover" &&
                        useShapeInteraction) {
                        strokeWeight = zIndex = 2;
                        opacity = 1;
                    }

                    return {
                        strokeWeight,
                        strokeColor: "#fff",
                        zIndex,
                        fillColor: `#${ color }`,
                        fillOpacity: opacity,
                        transition: 'all 0.3s ease',
                        cursor: useShapeInteraction ? 'pointer' : 'normal'
                    }
                });
                map.data.addGeoJson(shapes);
                map.data.addListener("mouseover", e => {
                    e.feature.setProperty("state", "hover");
                });
                map.data.addListener("mouseout", e => {
                    e.feature.setProperty("state", "normal");
                });
                map.data.addListener("click", e => {
                    if (useShapeInteraction) {
                        let html = props.getShapeLabel ? props.getShapeLabel(e.feature) : "";
                        info.setContent(html);
                        info.setPosition(e.latLng);
                        info.open(map);
                    }
                });
            }

            if (!props.zoom) {
                map.fitBounds(bounds);
            }
        }
    }

    useEffect(() => {
        if (map) {
            if (markerColor || (points && points[0] && points[0].color)) {
                loadLibraries().then(results => {
                    const AdvancedMarkerElement = results.AdvancedMarkerElement;
                    const PinElement = results.PinElement;
                    populateMap(map, AdvancedMarkerElement, PinElement);
                });
            } else {
                populateMap(map);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, points, shapes])

    return (
        <ErrorBoundary>
            <div ref={ref} className="Map" id={ props.id } key={ props.id }>
                { loadButton && map && (
                    <MapControl map={ map } position="BOTTOM_CENTER">{ loadButton }</MapControl>
                )}
            </div>
        </ErrorBoundary>
    );
})

export default Map;