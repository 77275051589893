import ContactForm from './ContactForm';

function ContactPage() {
	return (
		<>
	      <h1>Contact</h1>
	      <ContactForm />
	    </>
	);
}

export default ContactPage;