import { useState, useEffect, useRef } from 'react';
import { useDimensions } from '../../lib/useDimensions';
import PhotoGalleryImage from './PhotoGalleryImage/PhotoGalleryImage';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const PHOTOS_PROVIDER = [
	"kauai.jpg",
	"cascades.jpg",
	"cascades2.jpg",
	"colorado.jpg",
	"colorado2.jpg",
	"colorado3.jpg",
	"dr.jpg",
	"dr2.jpg",
	"dr3.jpg",
	"florida.jpg",
	"hawaii.jpg",
	"michigan.jpg",
	"banff.jpg",
	"banff2.jpg",
	"banff3.jpg",
	"banff4.jpg",
	"banff5.jpg",
	"banff6.jpg",
	"oregon.jpg",
	"oregon2.jpg",
	"utah.jpg",
	"wisconsin.jpg",
	"yellowstone.jpg",
	"yellowstone2.jpg"
];

const SINGLE_COLUMN_CUTOFF_WIDTH = 732;

function PhotoGallery() {
	const galleryRef = useRef(null);
	const { width } = useDimensions(galleryRef);
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [images, setImages] = useState([]);

	/* Randomize array */
	function shuffleArray(array) {
	    for (let i = array.length - 1; i > 0; i--) {
	        const j = Math.floor(Math.random() * (i + 1));
	        [array[i], array[j]] = [array[j], array[i]];
	    }

	    return array;
	}

	useEffect(() => {
		setImages(shuffleArray(PHOTOS_PROVIDER));
	}, []);

	return (
		<ErrorBoundary>
			<div className="PhotoGallery" ref={ galleryRef } >
				{ images.map((img, i) => {
					return (
						<PhotoGalleryImage
							key={ i }
							currentIndex={ i }
							selectedIndex={ selectedIndex }
							onSelectIndex={ width >= SINGLE_COLUMN_CUTOFF_WIDTH ? setSelectedIndex : undefined }
							image={ img }
						/>
					);
				})}
			</div>
		</ErrorBoundary>
	);
}

export default PhotoGallery;