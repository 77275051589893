import './DragAndDropUploader.css';
import { useRef, useState, useEffect } from 'react';

function DragAndDropUploader(props) {
	const [isDragging, setIsDragging] = useState(false)
	const [isInvalid, setIsInvalid] = useState(false)
	const inputRef = useRef(null);

	useEffect(() => {
		if (isInvalid) {
			setTimeout(() => {
				setIsInvalid(false);
			}, 3000);
		}
	}, [isInvalid]);

	/**
	 * Handler for dragging a file over the button
	 *
	 * @param      {<type>}  e       The event
	 */
	const handleDrag = e => {		
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || 
			e.type === "dragover") {
			setIsDragging(true);
		} else if (e.type === "dragleave") {
			setIsDragging(false);
		}
	}

	/**
	 * Handler for dropping a file on the button
	 *
	 * @param      {<type>}  e       The event
	 */
	const handleDrop = e => {
		e.preventDefault();
		e.stopPropagation();
		setIsDragging(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			if (e.dataTransfer.files[0].type === 'text/csv') {
				handleUpload(e.dataTransfer.files[0]);
			} else {
				setIsInvalid(true);
			}
		}
	};

	const handleChange = e => {
		if (e.target.files && e.target.files[0]) {
			if (e.target.files[0].type === 'text/csv') {
				handleUpload(e.target.files[0]);
			} else {
				setIsInvalid(true);
			}
		}
	}

	const handleUpload = file => {
		let reader = new FileReader();
		reader.readAsText(file);

		reader.onload = function() {
			props.onUpload(reader.result);
		};
	};

	return (
		<div className="DragAndDropUploader">
			<input
				id="DragAndDropUploader-fileInput"
				className="DragAndDropUploader-fileInput"
				ref={ inputRef }
				type="file"
				accept="text/csv"
				onChange={ handleChange }
			/>
			<label 
				htmlFor="DragAndDropUploader-fileInput" 
				className={`
					DragAndDropUploader-button 
					${ isDragging ? 'is-dragging' : '' }
				`}
				onDragEnter={ handleDrag } 
				onDragLeave={ handleDrag } 
				onDragOver={ handleDrag } 
				onDrop={ handleDrop }
			>
				Click or drag and drop CSV
			</label>
			<span 
				className={`
					DragAndDropUploader-invalidText
					${ isInvalid ? 'is-invalid-upload' : '' }
				`}
			>Hey! I only accept CSV files!</span>
		</div>
	);
}

export default DragAndDropUploader;