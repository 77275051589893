import PhotoGallery from '../PhotoGallery/PhotoGallery';

function PhotographyPage() {
	return (
		<>
		  <h1>Photography</h1>
		  <p className="PhotoGallery-description">
		    I've always enjoyed being in and capturing nature through a lens. I hope you enjoy some of my favorite shots.
		  </p>
		  <PhotoGallery />
		</>
	);
}

export default PhotographyPage;