import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

function ErrorBoundary(props) {
	return (
		<ReactErrorBoundary
			fallbackRender={() => {
				<div className="ErrorMessage">
					Oops, looks like you found an error. You can try refreshing, but if the issue persists I'd definitely appreciate reaching out on the Contact Form.
				</div>
			}}
			onError={console.error}
		>
			{ props.children }
		</ReactErrorBoundary>
	);
}

export default ErrorBoundary;