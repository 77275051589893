function PhotoGalleryImage(props) {
	const {selectedIndex, currentIndex, image, onSelectIndex} = props;

	return (
		<div
			className={ `PhotoGallery-imageCell ${ selectedIndex === currentIndex ? 'is-selected' : ''}` }
			onClick={ () => {
				if (onSelectIndex) {
					onSelectIndex(currentIndex)
				}
			}}
		>
			<div 
				className="PhotoGallery-image"
				style={{
					backgroundImage: `url('img/${ image }')`
				}}
			>
				{ selectedIndex === currentIndex && (
					<span 
						className="PhotoGallery-closeIcon" 
						onClick={ e => {
							e.stopPropagation();
							if (onSelectIndex) {
								onSelectIndex(null); 
							}
						}}>X</span>
				)}
			</div>
		</div>
	);
}

export default PhotoGalleryImage;