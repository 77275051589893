import { useState } from 'react';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

function ContactForm() {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");

	const formIsValid = name &&
		email &&
		phone &&
		message;

	return (
		<ErrorBoundary>
			<form className="ContactForm" action="https://formsubmit.co/628e9bc611700102cfa409bae0b40493" method="POST">
				All fields are required.
				<input type="hidden" name="_subject" value="Contact Form at KyleCrossman.com" />
				<input type="text" name="_honey" style={{display:"none"}} />
				<label className="ContactForm-label" htmlFor="name">
					Full Name
					<input 
						className="ContactForm-input"
						type="text" 
						id="name" 
						name="name" 
						value={ name } 
						onChange={ e => {
							setName(e.target.value)
						}} 
					/>
				</label>
				<label className="ContactForm-label" htmlFor="email">
					E-mail Address
					<input 
						className="ContactForm-input" 
						type="text" 
						id="email" 
						name="email" 
						value={ email } 
						onChange={ e => {
							setEmail(e.target.value)
						}} 
					/>
				</label>
				<label className="ContactForm-label" htmlFor="phone">
					Phone Number
					<input 
						className="ContactForm-input" 
						type="text" 
						id="phone" 
						name="phone" 
						value={ phone } 
						onChange={ e => {
							setPhone(e.target.value)
						}} 
					/>
				</label>
				<label className="ContactForm-label" htmlFor="message">
					Message
					<textarea 
						className="ContactForm-input ContactForm-textArea" 
						id="message" 
						name="message" 
						value={ message } 
						onChange={ e => {
							setMessage(e.target.value)
						}} 
					/>
				</label>
				<button className="ContactForm-submit App-button" disabled={ !formIsValid }>
					Submit
				</button>
			</form>
		</ErrorBoundary>
	);
}

export default ContactForm;