import { useState } from 'react';
import './CarouselUploaderPage.css';
import DragAndDropUploader from '../../DragAndDropUploader/DragAndDropUploader';
import Graph from '../../Graph/Graph';
import Map from '../../Map/Map';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { useErrorBoundary } from "react-error-boundary";

const cleanInput = str => {
	return str.trim().replaceAll("\"", "");
}

function CarouselUploaderPage(props) {
	const [data, setData] = useState([]);
	const [showGraph, setShowGraph] = useState(false);
	const [showMap, setShowMap] = useState(false);
	const [useColors, setUseColors] = useState(false);
	const [useCategories, setUseCategories] = useState(false);
	const { showBoundary } = useErrorBoundary();

	const handleUpload = csv => {
		try {
			const rows = csv.replaceAll("\r", "").split('\n');
			const headers = rows[0].split(',').filter(item => {
				return item.trim() !== "" &&
					item !== "\\r";
			});
			const baseItem = {}
			headers.forEach(header => {
				baseItem[cleanInput(header)] = "";
			});

			rows.shift();
			const data = rows.filter(row => {
				// Remove empty row of commas that we can sometimes get
				return row.match(/[,]+/g);
			}).map(row => {
				// Copy item
				const item = Object.assign({}, baseItem);
				const splitRow = row.split(',');
				headers.forEach((header, i) => {
					header = cleanInput(header);
					if (header === 'Latitude' ||
						header === 'Longitude' ||
						header === 'Amount') {
						item[header] = Number(cleanInput(splitRow[i]));
						if (isNaN(item[header])) {
							item[header] = null;
						}
					} else {
						item[header] = cleanInput(splitRow[i]);
					}
				});

				return item;
			});

			setData(data);

			let hasAmount = false;
			let hasLatitude = false;
			let hasLongitude = false;
			let hasColor = false;
			let hasCategory = false;

			if (data.length > 0) {
				hasAmount = data[0].hasOwnProperty('Amount');
				hasLatitude = data[0].hasOwnProperty('Latitude');
				hasLongitude = data[0].hasOwnProperty('Longitude');
				hasColor = data[0].hasOwnProperty('Color');
				hasCategory = data[0].hasOwnProperty('Category');
			}

			setShowGraph(hasAmount);
			setShowMap(hasLatitude && hasLongitude);
			setUseColors(hasColor);
			setUseCategories(hasCategory);
		} catch (error) {
			showBoundary(error);
		}
	};

	const generateCategories = (catData, useCategories) => {
		if (useCategories) {
			const reduceHelper = {};
			return catData.reduce((carry, current) => {
				if (!reduceHelper[current.Category]) {
					carry.push(current.Category);
					reduceHelper[current.Category] = true;
				}

				return carry;
			}, []);
		}

		return catData.map(row => {
			return row[Object.keys(row)[0]];
		});
	};

	const generateSeries = (data, useCategories) => {
		let seriesData = data;
		if (useCategories) {
			const reduceHelper = {};
			seriesData = data.reduce((carry, current) => {
				if (reduceHelper.hasOwnProperty(current.Category)) {
					carry[reduceHelper[current.Category]].Amount += current.Amount;
				} else {
					carry.push({Amount: current.Amount});
					reduceHelper[current.Category] = carry.length - 1;
				}
				
				return carry;
			}, [])
		}

		return [{
			name: "Amount",
			data: seriesData.map(row => {
				return row.Amount;
			}),
			showInLegend: false
		}]
	};

	const generateColors = (data, useColors, useCategories) => {
		if (useColors) {
			let colorsData = data;
			if (useCategories) {
				const reduceHelper = {};
				colorsData = data.reduce((carry, current) => {
					if (!reduceHelper[current.Category]) {
						carry.push({Color: current.Color});
						reduceHelper[current.Category] = current.Category;
					}

					return carry;
				}, []);
			}

			return colorsData.map(row => {
				return row.Color;
			});
		}

		return [];
	};

	const generatePoints = data => {
		return data.map(item => {
			return {
				position: {
					lat: item.Latitude, 
					lng: item.Longitude
				},
				color: item.Color,
				content: item[Object.keys(item)[0]]
			};
		});
	}

	const graphColorOptions = {};
	if (useColors) {
		graphColorOptions.colors = generateColors(data, useColors, useCategories);
	}

	return (
		<ErrorBoundary>
			<div className={ `CarouselUploaderPage ${ props.className }` }>
				<div className="CarouselUploaderPage-uploader">
					<div className="Card">
						<h2>Upload A CSV</h2>
						<p>
							Provide this view with it's data source! This view will behave differently depending on the datasheet you provide:
						</p>
						<ul>
							<li>All columns will display in a table</li>
							<li>If there are columns named "Latitude" and "Longitude", this tool will use those values to display each row on a map.</li>
							<li>If an "Amount" number field is present, this view will create a bar chart with the numbers entered</li>
							<li>The graph and map will use the first field for labels</li>
							<li>Otherwise, if a "Category" text field is provided in addition to "Amount", the graph will group by the text categories provided</li>
							<li>If a "Color" text field is provided, both views will attempt to respect the hex codes provided. With Categories, they'll use the first Color found.</li>
							<li>If the conditions for a Map and Graph are both met, both views will display</li>
						</ul>
						<p>
							Don't have a sheet handy? <a href="data/SampleData.csv" download>You can demo with this one!</a>
						</p>
						<DragAndDropUploader onUpload={ handleUpload } />
					</div>
				</div>
				{ data.length > 0 && (
					<div className="CarouselUploaderPage-gridContainer">
						<div className="Card">
							<h2>Your Data</h2>
							<div className="CarouselUploaderPage-grid">
								<table>
									<thead>
										<tr>
											{ Object.keys(data[0]).map(header => {
												return <th key={header}>{header}</th>
											}) }
										</tr>
									</thead>
									<tbody>
										{ data.map((row, i) => {
											return (
												<tr key={ i }>
													{ Object.keys(row).map(header => {
														return <td key={`${i}-${header}`}>{row[header]}</td>
													}) }
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				)}
				{ showGraph && (
					<div className="CarouselUploaderPage-graph">
						<div className="Card">
							<h2>Graph by Amount</h2>
							<Graph
								options={
									{
							            chart: {
							                type: 'column'
							            },
							            title: {
							                text: useCategories ? 'Amount By Category' : 'Amount By Row'
							            },
									    plotOptions: {
									        column: {
									            colorByPoint: true
									        }
									    },
							            xAxis: {
							                categories: generateCategories(data, useCategories)
							            },
							            yAxis: {
							                title: {
							                    text: 'Amount'
							                }
							            },
							            series: generateSeries(data, useCategories),
							            ...graphColorOptions
							        }
								} 
							/>
						</div>
					</div>
				)}
				{ showMap && (
					<div className="CarouselUploaderPage-map">
						<div className="Card">
							<h2>Map Card</h2>
							<Map
								id="Uploader-Map"
								mapId="6fc2eb36601133f0"
								points={ generatePoints(data) }
							/>
						</div>
					</div>
				)}
			</div>
		</ErrorBoundary>
	);
}

export default CarouselUploaderPage;