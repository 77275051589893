function AboutPage() {
	return (
		<>
	      <h1>About Me</h1>
	      <h2>The Quick Summary</h2>
	      <ul>
	        <li>Based in Madison, WI</li>
	        <li>Currently serve as the Chief Technology Officer of <a href="https://www.hardindd.com" target="_blank" rel="noreferrer">Hardin Design &amp; Development</a></li>
	        <li>Years of experienced building and leading software teams</li>
	        <li>Specialize in web application development</li>
	        <li>Experienced Full Stack Engineer</li>
	      </ul>
	      <h2>The Full Story</h2>
	      <div className="About-fullStory">
	        <section>
	          <img src="./img/meeting.jpg" alt="A business meeting" />
	          <h3>Tech Leadership</h3>
	          <p>
	            I currently serve as the Chief Technology Officer of <a href="https://www.hardindd.com" target="_blank" rel="noreferrer">Hardin Design &amp; Development</a>. As part of this role,
	            I have served as the lead developer and architect of <a href="https://fischercre.com/visual-manager" target="_blank" rel="noreferrer">Visual Manager</a>, 
	            a business intelligence solution.
	          </p>
	        </section>
	        <section>
	          <img src="./img/code.jpg" alt="A laptop with some code" />
	          <h3>Full Stack Web Development</h3>
	          <p>
	            I am used to wearing many hats in my role. I can perform at a high level in front-end development, back-end development, devops, cloud engineering, and database administration. 
	          </p>
	        </section>
	        <section>
	          <img src="./img/interview.jpg" alt="A man in front of a whiteboard" />
	          <h3>Software Team Building</h3>
	          <p>
	            I have over a decade of experience in sourcing, evaluating, and acquiring talented web developers, project managers and quality assurance engineers across different levels of experience.
	          </p>
	        </section>
	      </div>
	    </>
	);
}

export default AboutPage;