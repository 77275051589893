import { useState } from 'react';
import './ViewCarousel.css';
import ViewCarouselView from './ViewCarouselView/ViewCarouselView';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const views = [
	{
		type: "presentation",
		name: "Interactive Presentation Builder",
		problem: "We needed the ability to build presentations utilizing all the data visualization tools the system offered. The ultimate storytelling tool. On top of this, the views needed to easily export and look great on paper and on a screen.",
		solution: `Delivered an intuitive web experience for building interactive presentations. To export, PDF generation was the natural solution.
 Build a Puppeteer microservice using Node to process multi page exports and combine them into a single PDF. Build out e-mail subscriptions to
 keep users informed`
	}, {
		type: "map",
		name: "Customizable Map View",
		problem: "An excellent mapping solution is a must for any data visualization software dealing with geographical data. It needs to be fast, offer a large number of features, and the visualizations need to be modular.",
		solution: `Built a bridge with React to the mapping software. Leaflet checked all the boxes for the base mapping platform. My team and I built a rich suite of data visualization tools and plugins. The mapping solution continues to be a major selling point for the software.`
	}, {
		type: "graph",
		name: "Interactive Graph Builder",
		problem: "Charting solutions are an ubiquitous method of performing data analysis in a visual medium. We needed the ability to build custom, modular, and interactive graphs.",
		solution: "Highcharts is a well known charting library that was an easy choice in a Javascript environment. We built an interface between the complex configurations more suited for a developer and a simple UI that gave users the power needed."
	}, {
		type: "template",
		name: "Template & Data Import Tool",
		problem: "It took a significant amount of time to build the sophisticated solutions that clients wanted. We needed a way to streamline this process that was performant, powerful, and easy to use.",
		solution: "We had to rethink how solutions were delivered using the platform. Rewrite critical system functionality with a test-driven approach to make the needed upgrades. Utilize Laravel's Eloquent ORM to quickly replicate views and configurations. Build a simple and intuitive UI that alerts users of issues ahead of time."
	}, {
		type: "docker",
		name: "Containerize Monolith Application",
		problem: "Deploying updates can be a complicated ordeal. We were looking to simplify our processes and have our developers working with the same environment that production offered.",
		solution: "Switching to Docker and containerizing the app meant we could easily replicate the production environment locally. It also offered the advantage of simplifying the addition of microservices like the PDF printing tool. Leveraging Compose ECS meant we could keep the same base configuration for every environment."
	}
];

function ViewCarousel() {
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [isDescOpen, setIsDescOpen] = useState(1);

	const renderView = (viewIndex) => {
		return (
			<>
				<button
					className="ViewCarousel-button ViewCarousel-buttonLeft"
					disabled={ viewIndex === 0 }
					onClick={ () => {
						setSelectedIndex(viewIndex => viewIndex - 1)
					}}
				>
					<span className="chevron left"></span>
				</button>
				<div className="ViewCarousel-viewContainer">
					<ViewCarouselView
						key={ views[viewIndex].type }
						view={ views[viewIndex].type }
						hasFooter={ isDescOpen }
						onChangeIndex={ i => setSelectedIndex(i) }
					/>
					<div className={ `ViewCarousel-viewFooter ${ !isDescOpen ? 'is-closed' : '' }` }>
						<button 
							className="ViewCarousel-viewDescToggle"
							onClick={ () => {
								setIsDescOpen(!isDescOpen);
							}}
							title={ `${ isDescOpen ? "Close" : "Expand" } Descriptions` }
						>
	       					{ isDescOpen ? (<span className="chevron bottom"></span>) : (<span className="chevron top"></span>) }
						</button>
						<div className="ViewCarousel-viewDescContainer">
							<h1>{ views[viewIndex].name }</h1>
							<div className="ViewCarousel-viewDescriptions">
								<div className="ViewCarousel-problem">
									<h2>The Problem</h2>
									<p>{ views[viewIndex].problem }</p>
								</div>
								<div className="ViewCarousel-solution">
									<h2>The Solution</h2>
									<p>{ views[viewIndex].solution }</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<button
					className="ViewCarousel-button ViewCarousel-buttonRight"
					disabled={ viewIndex === views.length - 1  }
					onClick={ () => {
						setSelectedIndex(viewIndex => viewIndex + 1)
					}}
				>
					<span className="chevron right"></span>
				</button>
			</>
		);
	}

	return (
		<ErrorBoundary>
			<div 
				className="ViewCarousel"
			>
				{ views.map((view, i) => {
					if (Math.abs(i - selectedIndex) > 1)
					{
						return '';
					}

					return (
						<div 
							key={ view.name } 
							className="ViewCarousel-carouselItem"
							style={{
								left: `${ (i - selectedIndex) * 100 }%`,
								opacity: i === selectedIndex ? 1 : 0.25
							}}
						>
							{ renderView(i) }					
						</div>
					);
				})}
			</div>
		</ErrorBoundary>
	);
}

export default ViewCarousel;