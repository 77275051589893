import './CarouselGraphView.css';
import Graph from '../../Graph/Graph';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';

/*
 * Bar chart
 * Pie chart
 * 
 * Pie
 * Hollow Pie
 * Pie
 * Hollow Pie
 * 
 * Line Chart
 * 
 */

function CarouselGraphView(props) {
	return (
		<ErrorBoundary>
			<div
				className={ `${ props.className } CarouselGraphView` }
			>
				<div className="CarouselGraphView-column">
					<Graph 
						options={
							{
					            chart: {
					                type: 'column'
					            },
					            title: {
					                text: "Culver's Sales By Year"
					            },
								tooltip: {
									valuePrefix: "$"
								},
					            xAxis: {
					                categories: [2018, 2019, 2020, 2021]
					            },
					            yAxis: {
					                title: {
					                    text: 'Revenue'
					                }
					            },
					            series: [{
					                name: 'Revenue',
					                data: [1576000000, 1795000000, 1986000000, 2489000000],
					                color: '#005599'
					            }]
					        }
						} 
					/>
					<Graph 
						options={
							{
					            chart: {
					                type: 'line'
					            },
					            title: {
					                text: "Dane County Median Sale Price"
					            },
								tooltip: {
									valuePrefix: "$"
								},
					            xAxis: {
					                categories: [2019, 2020, 2021, 2022, 2023]
					            },
					            yAxis: {
					                title: {
					                    text: 'Sale Price'
					                }
					            },
					            series: [{
					                name: 'Sale Price',
					                data: [305000, 305000, 345000, 380000, 425000]
					            }]
					        }
						} 
					/>
				</div>
				<div className="CarouselGraphView-column">
					<section className="CarouselGraphView-smallCharts">
						<Graph options={{
							chart: {
								type: "pie"
							},
							title: {
								text: "Cheese Curd Sales"
							},
							tooltip: {
								valueSuffix: "%"
							},
							series: [{
								name: "Cheese Curd Sales Share",
								colorByPoint: true,
								data: [{
									name: "Culver's",
									y: 95,
									color: "#005599"
								}, {
									name: "Everyone Else",
									y: 5,
									color: "#f10c26"
								}]
							}]
						}} />
						<Graph options={{
							chart: {
								type: "pie"
							},
							title: {
								text: "Culver's Search Interest (Top 5)"
							},
							series: [{
								name: "Culver's Google Search Interest",
								colorByPoint: true,
								data: [{
									name: "Wisconsin",
									y: 100,
									color: "#005599"
								}, {
									name: "South Dakota",
									y: 41,
								}, {
									name: "Iowa",
									y: 36,
								}, {
									name: "Minnesota",
									y: 36,
								}, {
									name: "Illinois",
									y: 34,
								}]
							}]
						}} />
					</section>
					<section>
						<Graph 						
							options={
								{
						            chart: {
						                type: 'column'
						            },
						            title: {
						                text: "Burger Franchises 2019-2020 Sales Change"
						            },
									tooltip: {
										valueSuffix: "%"
									},
						            xAxis: {
						                categories: [
						                	"Culver's", 
						                	"In-N-Out Burger",
						                	"Whataburger", 
						                	"Jack in the Box", 
						                	"Wendy's", 
						                	"Five Guys", 
						                	"McDonald's", 
						                	"Burger King",
						                	"Hardees", 
						                ],
						                labels: {
						                	autoRotation: false
						                }
						            },
						            yAxis: {
						                title: {
						                    text: 'Percent Growth'
						                },
						                min: -10,
						                max: 15,
						                tickAmount: 7
						            },
								    plotOptions: {
								        column: {
								            colorByPoint: true
								        }
								    },
						            series: [{
						            	name: "Percent Growth",
						                data: [
						                	10.6, 
						                	9.5,
						                	5.6, 
						                	4.8, 
						                	4.8,
						                	3, 
						                	0.3, 
						                	-5.4, 
						                	-9.5, 
						                ],
						                showInLegend: false
						            }],
						            colors: [
						            	"#005599",
						            	"#FFCB05",
						            	"#FF770F",
						            	"#E51837",
						            	"#dd1438",
						            	"#c92027",
						            	"#FFC72C",
						            	"#DA291C",
						            	"#f10c26",
						            ]
						        }
							} 
						/>
					</section>
				</div>
			</div>
		</ErrorBoundary>
	);
}

export default CarouselGraphView;