import './ViewCarouselView.css';
import CarouselMapView from '../CarouselMapView/CarouselMapView';
import CarouselGraphView from '../CarouselGraphView/CarouselGraphView';
import CarouselUploaderPage from '../CarouselUploaderPage/CarouselUploaderPage';
import CarouselDockerPage from '../CarouselDockerPage/CarouselDockerPage';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';

function ViewCarouselView(props) {
	const getClassName = () => {
		return `ViewCarouselView ${ props.hasFooter ? 'with-footer' : '' }`
	}

	const views = {
		presentation: (
			<div className={ `${ getClassName() } ViewCarouselView-titleSlide` }>
				<h1>My Work</h1>
				<p>
					I have worked on many complex and exciting projects. However, most of my recent work is proprietary and I cannot share it
					on my personal portfolio. As such, I decided to replicate similar minimalist features to show a little bit of what I can do. The
					first project is the <b>Interactive Presentation Builder</b> - and the demo is this page!
				</p>
				<p className="ViewCarouselView-mobileFriendly">
					Please note: This presentation is not currently available for small screens. Fret not, a fully responsive experience is coming soon!
				</p>
			</div>
		),
		map: <CarouselMapView className={ getClassName() } />,
		graph: <CarouselGraphView className={ getClassName() } />,
		template: <CarouselUploaderPage className={ getClassName() } />,
		docker: <CarouselDockerPage className={ getClassName() } />
	}

	return (
		<ErrorBoundary>
			{ views[props.view] }
		</ErrorBoundary>
	);
}

export default ViewCarouselView;