import { useRouteError, Link } from "react-router-dom";
import Logo from '../App/Logo/Logo';
import './RouteNotFoundPage.css';

function RouteNotFoundPage() {
	const error = useRouteError();
	console.error(error);

	return (
		<div className="RouteNotFoundPage">
			<h1>Oh no!</h1>
			<p>An error occurred with your request:</p>
			<p className="Error">
		    	<b>Error:</b> <i>{error.statusText || error.message}</i>
			</p>
			<p>Click the logo or use your browser's back button to return to my website.</p>
			<Link to='/about'>
				<Logo />
			</Link>
		</div>
	);
}

export default RouteNotFoundPage;